
import { computed, defineComponent, onMounted, onUpdated } from "vue";
import { LoadEntity } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import ProviderForm from "@/views/provider/ProviderForm.vue";
import router from "@/router";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "UpdateProvider",
  components: {ProviderForm},
  setup() {
    const provider = computed(() => store.state.ProviderModule.provider);

    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Update Provider', [
        {link: false, router: '', text: 'Update Provider'}
      ])
    })
    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Update Provider', [
        {link: true, router: '/policies/' + provider.value.caze?.policy, text: 'View Policy'},
        {link: true, router: '/cases/' + provider.value.caze?.id, text: 'View Case'},
        {link: false, router: '', text: 'Update Provider'}
      ])
    })

    return {
      ...LoadEntity(Actions.LOAD_PROVIDER),
      provider
    }
  },
  methods: {
    onSaved() {
      router.push({
        name: 'caseOverview',
        params: {id: this.provider?.caze?.id}
      })
    }
  }
})
